import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked, Input } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { Review, Submission } from 'src/app/model/paper';
import { SubmissionsFilter } from 'src/app/enum/submissions.filter';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { EventsService } from 'src/app/service/events.service';
import { SelectOption } from 'src/app/model/select.option';
import { SubmissionStatus } from 'src/app/enum/submission.status';
import { dictControlFields, specialFields, columnsOrder, dictControlFilters, groupFields, publicationFields, specialColumnsFields, ColorSchemeFilter } from 'src/app/model/submissionFieldFilter';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { Rank } from 'src/app/class/rank';
import { ReviewStatus } from 'src/app/enum/review.status';
import { plainToClass } from 'class-transformer';
import { FormFieldChoice } from 'src/app/model/form.field.choice';
import { Form } from 'src/app/model/form';
import { FormField } from 'src/app/model/form.field';
import { arrayHasElements, shallowCopyArray, isEqual, intersectionArrays, isValidNumber } from 'src/app/utils/utils';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { defaultFields } from 'src/app/enum/submissions.field';
import { PublicationService } from 'src/app/service/publication.service';
import { CheckListField } from 'src/app/model/checklist-field';
import { File } from 'src/app/model/file';
import { FormFieldCategory } from 'src/app/enum/form-field-category';
import { ExportService } from 'src/app/service/export.service';
import { STYLES_EXPORT_TABLE_V2 } from 'src/app/utils/styles';
import { SVG } from 'src/app/utils/svg';

export interface SubmissionRanking {
  submission: Submission;
  rank: Rank;
  selected: boolean;
}

@Component({
  selector: 'app-submissions-list-table-v2[page]',
  templateUrl: './submissions-list-table-v2.component.html',
  styleUrls: ['./submissions-list-table-v2.component.scss']
})
export class SubmissionsListTableV2Component implements OnInit, OnDestroy, AfterContentChecked {
  @Input() private readonly page: string;

  public SubmissionStatus = SubmissionStatus;

  public event: Event;
  private eventReviewConfiguration: EventReviewConfiguration;
  public submissionRanking: SubmissionRanking[];

  public displayedColumns: string[] = ['position', 'id', 'status', 'description', 'group', 'event', 'track', 'select'];
  public fieldsShow: string[] = [];
  private fieldsShowOrdered: string[] = []; // This is necessary to know which column is the first and the last one.
  public dataSource: MatTableDataSource<SubmissionRanking>;

  public formFilters: FormGroup;
  public formFields: FormControl;
  
  public customFields: Array<{id: number, label: string, control: string, name: string}> = [];
  private customCheckListSubmissions: {} = {};
  public customControlForm: FormGroup;
  
  private previousFilter: {} = {};
  private tableSort: { sortBy: string, reverseSort: boolean };
  
  public submissionForm: Form;
  private reviewForm: Form;
  
  public paperGroupForm: FormGroup;
  public paperGroupList: Array<SelectOption>;

  public TPCGroupForm: FormGroup;
  public TPCGroupList: Array<SelectOption>;

  public statusForm: FormGroup;
  public status: Array<SelectOption> = SubmissionStatus.toSelectable();
  public iconCollection: { [key: string]: { fontSet: string, fontIcon: string, color: string } } = {};

  public readonly BUTTON_TYPE_ICONS = {CHECK: 'fa-check-square', UNCHECK: 'fa-square', INDETERMINATE: 'fa-minus-square'};
  private toggleMarkAll: boolean = false;

  private POSITION_PUBLICATION: number = null;

  private previousElementHovered: HTMLElement;

  private colorScheme: number = ColorSchemeFilter['Submission status'];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;

      if (!this.dataSource.sort.active) {
        this.initTableSorted();
      }

      this.dataSource.sortingDataAccessor = (item: SubmissionRanking, header: string) => {
        switch (header) {
          case 'id': return item.submission.id;
          case 'status': return item.submission.status;
          case 'description': return item.submission.title.toLowerCase();
          case 'group': return this.paperGroupList.find(group => group.id === item.submission.paperGroup)?.value || 'Ω';
          case 'tpc-group': return this.TPCGroupList.find(TPCGroup => TPCGroup.id === item.submission.tpcGroup)?.value || 'Ω';
          case 'track': return item.submission.trackName;
          case 'rebuttal': return item.submission.rebuttal;
          case 'discussion-messages': return item.submission.discussionMessages.length;
          case 'reviews': return item.submission.reviews.length;
          case 'score-average-arithmetic': return item.rank.average;
          case 'score-average-weighted': return item.rank.weightedAverage;
          case 'score-span': return item.rank.span;

          default: return item[header];
        }
      };
    }
  }

  constructor(
    private adminService: AdminService,
    private submissionsService: SubmissionsService,
    private fb: FormBuilder,
    private eventsService: EventsService,
    private reviewConfigurationService: ReviewsConfigurationService,
    private publicationService: PublicationService,
    private cdr: ChangeDetectorRef,
    private exportService: ExportService
  ) {
    this.iconCollection = {
      'ACCEPTED': {...SubmissionStatus.icon(SubmissionStatus.ACCEPTED), color: '#99FF99'},
      'ACTIVE': {...SubmissionStatus.icon(SubmissionStatus.ACTIVE), color: '#FFFF55'},
      'REJECTED': {...SubmissionStatus.icon(SubmissionStatus.REJECTED), color: '#FFBDBD'},
      'PENDING': {...SubmissionStatus.icon(SubmissionStatus.PENDING), color: '#FAB65C'},
      'WITHDRAWN': {...SubmissionStatus.icon(SubmissionStatus.WITHDRAWN), color: '#DDDDDD'},
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
  
      if (!this.event) {
        this.getEvent();
        return;
      }
      
      this.getData();
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.resetPublicationFields();
  }

  private resetPublicationFields(): void {
    /*
      * This method is necessary to remove references the dynamic fields
    */
    if (this.POSITION_PUBLICATION) groupFields.splice(this.POSITION_PUBLICATION, 1);
    const indexesPublication = columnsOrder.map((field, index) => field.startsWith(publicationFields.customFields) ? index : null).filter(index => index);
    columnsOrder.splice(indexesPublication[0], indexesPublication.length);
  }

  private getEvent(): void {
    this.adminService.getEvent().subscribe({
      next: (event: Event) => this.event = event,
      complete: () => this.getData()
    });
  }

  private getData(): void {
    this.getReviewForm();
    this.getFieldsAndFilters();
    this.getReviewConfiguration();
    this.getFormSubmission();
    this.getSubmissionAnswer();
    this.getTPCGroups();
  }

  private getReviewForm(): void {
    this.eventsService.getFormReview(this.event.id).subscribe({
      next: (reviewForm: Form) => this.reviewForm = reviewForm,
      complete: () => this.getSubmissions()
    });
  }

  private getSubmissions(): void {
    this.adminService.progressBar.start();
    this.submissionsService.getSubmissionsByEventFull(this.event.id, { detailed: true, reviews: false })
      .subscribe({
        next: (submissions: Submission[]) => {
          this.buildSubmissionRanking(submissions);
          this.initSubmissionGroupForm(submissions);
          this.initTPCGroupForm(submissions);
          this.getPaperGroups(this.event.id);
          this.initStatusForm(submissions);
          this.buildCustomControlForm(submissions);
        }
      });
  }

  private getFieldsAndFilters(): void {
    this.eventsService.getSubmissionFieldsAndFilters(this.event.id, this.page).subscribe((response) => {
      this.initFieldsForm(response);
      this.initFiltersForm(response);
      this.handlePublicationsFields();
      this.tableSort = { sortBy: response.sortBy, reverseSort: response.reverseSort };
    });
  }

  private getReviewConfiguration(): void {
    this.reviewConfigurationService.getReviewsConfiguration(this.event.id)
      .subscribe(config => this.eventReviewConfiguration = config);
  }

  private getFormSubmission(): void {
    this.eventsService.getFormSubmission(this.event.id)
      .subscribe(form => this.submissionForm = form);
  }

  private getSubmissionAnswer(): void {
    this.publicationService.getSubmissionsAnswers(this.event.id, {category: FormFieldCategory.CUSTOM})
      .subscribe((answers) => this.customCheckListSubmissions = answers);
  }

  private getPaperGroups(eventID: number): void {
    this.eventsService.getEventPaperGroups(eventID)
      .subscribe({
        next: (groups) => this.paperGroupList = groups.map(group => new SelectOption(group.id, group.name)),
        complete: () => this.filterSubmissions()
      });
  }

  private getTPCGroups(): void {
    this.eventsService.getEventTPCGroups(this.event.id)
      .subscribe(TPCgroups => {
        this.TPCGroupList = TPCgroups.map(TPCgroup => new SelectOption(TPCgroup.id, TPCgroup.name));
      })
  }

  private initSubmissionGroupForm(submissions: Submission[]): void {
    this.paperGroupForm = new FormGroup({});
    submissions.forEach(submission => {
      const name = `paperGroup-${submission.id}`;
      this.paperGroupForm.addControl(name, new FormControl(submission.paperGroup));
    });
  }

  private initTPCGroupForm(submissions: Submission[]): void {
    this.TPCGroupForm = new FormGroup({});
    submissions.forEach(submission => {
      const name = `TPCGroup-${submission.id}`;
      this.TPCGroupForm.addControl(name, new FormControl(submission.tpcGroup));
    });
  }

  private initFiltersForm(data: { [key: string] : any}): void {
    this.formFilters = this.fb.group({
      [SubmissionsFilter.STATUS]: [this.getFormFilterValue(SubmissionsFilter.STATUS, data)],
      [SubmissionsFilter.TOPICS]: [this.getFormFilterValue(SubmissionsFilter.TOPICS, data)],
      [SubmissionsFilter.TRACKS]: [this.getFormFilterValue(SubmissionsFilter.TRACKS, data)],
      [SubmissionsFilter.SUBMISSION_GROUPS]: [this.getFormFilterValue(SubmissionsFilter.SUBMISSION_GROUPS, data)],
      [SubmissionsFilter.TPC_GROUPS]: [this.getFormFilterValue(SubmissionsFilter.TPC_GROUPS, data)],
      [SubmissionsFilter.COLOR_SCHEME]: [this.page === 'ranking' ? ColorSchemeFilter['Submission status'] : ColorSchemeFilter['Publication status']],
    });
  }

  private initStatusForm(submissions: Submission[]): void {
    this.statusForm = new FormGroup({});
    submissions.forEach(submission => {
      const name = `status-${submission.id}`;
      this.statusForm.addControl(name, new FormControl(submission.status));
    });
  }

  private initFieldsForm(controls: {}): void {
    let fieldsShow = [defaultFields.POSITION];
    Object.keys(controls).forEach((key) => {
      const value = controls[key];
      const control = dictControlFields[key];

      if (value && control) {
        if (this.isSpecialFieldsColumn(control)) {
          this.addSpecialColumnsField(control, fieldsShow);
        }

        fieldsShow.push(control);
      }

    });
    fieldsShow.push(defaultFields.SELECT);
    
    this.getChecklistFields(controls, fieldsShow);
  }

  private getChecklistFields(fields: {}, fieldShow: string[]): void {
    this.publicationService.getEventCheckListFields(this.event.id)
      .subscribe((customFields: CheckListField[]) => this.handleCustomFields(fields, customFields, fieldShow));
  }

  private handleCustomFields(fields: {}, customFields: CheckListField[], fieldsShow: string[]): void {
    customFields.forEach(field => {
      const customField = { 
        id: field.id,
        label: field.description, 
        control: `${publicationFields.customFields}-${field.id}`, 
        name: `${publicationFields.customFields}-${field.id}` 
      };

      this.customFields.push(customField);
      groupFields[this.POSITION_PUBLICATION].fields.push(customField);
      dictControlFields[customField.control] = customField.control;
      columnsOrder.splice(columnsOrder.indexOf(defaultFields.SELECT), 0, customField.control);
    });

    this.setFieldsValues(fields, fieldsShow);
  }

  private setFieldsValues(fields: {}, fieldsShow: string[]): void {
    const customFieldsSaved = fields[publicationFields.customFields].split(',').filter(id => isValidNumber(id));
    fieldsShow = fieldsShow.concat(customFieldsSaved.map(id => `${publicationFields.customFields}-${id}`));

    this.formFields = new FormControl(fieldsShow);
    this.fieldsShow = shallowCopyArray(fieldsShow);
    this.fieldsShowOrdered = intersectionArrays(columnsOrder, fieldsShow);
    this.displayedColumns = this.removeSpecialFields(fieldsShow);
  }

  private removeSpecialFields(fields: string[]): string[] {
    const fieldsColumn = fields.filter(field => !specialFields.includes(field));
    return this.orderFields(fieldsColumn);
  }

  private getFormFilterValue(type: string, data: {}): number[] | string[] {
    const key = Object.keys(dictControlFilters).find(k => dictControlFilters[k] === type);

    if (type === SubmissionsFilter.STATUS) {
      return !!data[key] ? data[key]?.split(',') : [];
    }

    return !!data[key] ? data[key]?.split(',').map((id: string) => Number(id)) : [];
  }

  private handlePublicationsFields(): void {
    const { authorRegistrationEnable, copyrightEnable } = this.event;

    if (authorRegistrationEnable || copyrightEnable) {
      const publication = {
        label: 'forms.fields.filters.publication',
        fields: [],
        control: 'publication'
      };

      groupFields.push(publication);
      this.POSITION_PUBLICATION = groupFields.length - 1;
    }

    if (authorRegistrationEnable) {
      groupFields[this.POSITION_PUBLICATION].fields.push(publicationFields['registration']);
    }

    if (copyrightEnable) {
      groupFields[this.POSITION_PUBLICATION].fields.push(publicationFields['copyright']);
    }
  }

  public showSubmissions(): void {
    setTimeout(() => {
      this.filterSubmissions();
      this.fieldsShow = shallowCopyArray(this.formFields.value);
      this.fieldsShowOrdered = intersectionArrays(columnsOrder, this.fieldsShow);
      this.checkNewColumns();
      this.adminService.progressBar.stop();
    });
  }

  public filterSubmissions(): void {
    const onFilters = this.formFilters.value;
    this.colorScheme = onFilters.colorScheme;

    if (isEqual(this.previousFilter, onFilters)) return;
    
    this.previousFilter = onFilters;
    this.setTableSource(this.filteredSubmissions);
  }

  private checkNewColumns(): void {
    const newFields = this.formFields.value.filter(field => {
      if (specialFields.includes(field)) return false;
      return !this.displayedColumns.includes(field);
    });
    
    if (arrayHasElements(newFields)) {
      this.displayedColumns = this.displayedColumns.concat(newFields);
      this.displayedColumns = this.removeSpecialFields(this.displayedColumns);
      this.displayedColumns = this.orderFields(this.displayedColumns);
    }
  }

  private get filteredSubmissions(): SubmissionRanking[] {
    let submissions = this.submissionRanking;
    const { status, topics, tracks, submissionGroups, tpcGroups } = this.formFilters.value;

    if (arrayHasElements(status)) {
      submissions = submissions.filter(({submission}) => status.includes(submission.status));
    }

    if (arrayHasElements(topics)) {
      submissions = submissions.filter(({submission}) => submission.topics.some(topic => topics.includes(topic.id)));
    }

    if (arrayHasElements(tracks)) {
      submissions = submissions.filter(({submission}) => tracks.includes(submission.trackID));
    }

    if (arrayHasElements(submissionGroups)) {
      submissions = submissions.filter(({submission}) => submissionGroups.includes(submission.paperGroup));
    }

    if (arrayHasElements(tpcGroups)) {
      submissions = submissions.filter(({submission}) => tpcGroups.includes(submission.tpcGroup));
    }

    return submissions;
  }

  private setTableSource(submissions: SubmissionRanking[] = this.submissionRanking): void {
    this.dataSource = new MatTableDataSource(submissions);
    if (this.dataSource.sort?.active || !arrayHasElements(this.dataSource.data)) this.adminService.progressBar.stop();
  }

  private buildSubmissionRanking(submissions: Submission[]): void {
    this.submissionRanking = submissions.map((submission) => ({
      submission,
      rank: this.rank(submission),
      selected: false
    }));
  }

  private buildCustomControlForm(submissions: Submission[]): void {
    const submissionsID = submissions.map(submission => submission.id);
    this.customControlForm = new FormGroup({});
    submissionsID.forEach(id => {
      Object.keys(this.customCheckListSubmissions).forEach(key => {
        const name = `${key}-${id}`;
        const value = this.customCheckListSubmissions[key].includes(id);
        this.customControlForm.addControl(name, new FormControl(value));
      });
    });
  }

  private initTableSorted(): void {
    setTimeout(() => {
      this.dataSource.sort.active = this.tableSort.sortBy || 'id';
      this.dataSource.sort.direction = this.tableSort.reverseSort ? 'desc' : 'asc';
      this.dataSource.sort.sortChange.emit();
      this.adminService.progressBar.stop();
    });
  }

  public submissionsDeleted(submissionIDs: number[]): void {
    this.dataSource.data = this.dataSource.data.filter(({submission}) => !submissionIDs.includes(submission.id));
  }

  public updatePaperGroupList(options:Array<SelectOption>) {
    this.paperGroupList = options;
  }

  public updateTPCGroupList(options:Array<SelectOption>) {
    this.TPCGroupList = options;
  }

  public updateStatusForm(): void {
    this.statusForm.reset();
    this.initStatusForm(this.submissionRanking.map(({submission}) => submission));
  }

  public changeRowColor($event): void {
    this.dataSource.data.find(item => item.submission.id === $event.submissionID).submission.status = $event.value;
    this.statusForm.get(`status-${$event.submissionID}`).setValue($event.value);
  }
  
  public isSetField(field: string[]): boolean {
    let isSet = false;
    for (const f of field) {
      if (this.fieldsShow.includes(f)) {
        isSet = true;
        break;
      }
    }

    return isSet;
  }

  private isSpecialFieldsColumn(control: string): boolean {
    return Object.keys(specialColumnsFields).includes(control);
  }

  private addSpecialColumnsField(control: string, formsFieldsValue: string[]): void {
    Object.keys(specialColumnsFields).forEach(key => {
      if (key === control) {
        formsFieldsValue.push(...specialColumnsFields[key]);
      }
    });
  }

  public toggleSelection(submission: SubmissionRanking, $target: HTMLElement): void {
    submission.selected = !submission.selected;
    
    const parentTableRow = $target.closest('tr');
    this.addClassToTableRows('selected', false, parentTableRow);
  }

  public toggleAllSelection(valueToggle: boolean): void {
    this.toggleMarkAll = valueToggle ?? !this.toggleMarkAll;
    this.submissionRanking = this.submissionRanking.map(submission => {
      submission.selected = this.toggleMarkAll;
      return submission;
    });

    this.addClassToTableRows('selected', true);
  }

  public getIconToggleAll(): string {
    const selectionFirstSubmission = this.submissionRanking[0].selected;
    const hasDifferentSelection = this.submissionRanking.find(submission => submission.selected !== selectionFirstSubmission);

    if (hasDifferentSelection) return this.BUTTON_TYPE_ICONS.INDETERMINATE;
    return selectionFirstSubmission ? this.BUTTON_TYPE_ICONS.CHECK : this.BUTTON_TYPE_ICONS.UNCHECK;
  }

  private addClassToTableRows($class: string, allTableRows: boolean = false, selector?: HTMLElement): void {
    setTimeout(() => {
      if (!allTableRows) {
        selector.classList.toggle($class);
        return;
      }
  
      const rows = document.querySelectorAll('.submission-table tr');
      for (const row of rows) {
        if (this.toggleMarkAll) {
          row.classList.add($class);
          continue;
        }
        row.classList.remove($class);
      }
    });
  }

  public getColorSchemeClass(submission: Submission): string {
    if (this.colorScheme === ColorSchemeFilter['Publication status']) {
      const { authorRegistrationEnable, copyrightEnable } = this.event;

      if (authorRegistrationEnable && !submission.authorRegistration) return 'not-ready';

      if (copyrightEnable && !submission.copyrightIEEE) return 'not-ready';

      if (!this.isPublicationReady()[submission.id]?.includes(false)) return 'ready'

      return 'not-ready';
    }

    return submission.status.toLowerCase();
  }

  private isPublicationReady(): {} {
    const publicationFieldsValue = this.customControlForm.value;
    const submissionPublicationValues = {};

    Object.entries(publicationFieldsValue).forEach(([key, value]) => {
      const [field, submissionID] = key.split('-');

      if (!submissionPublicationValues[submissionID]) {
        submissionPublicationValues[submissionID] = [value];
      } else {
        submissionPublicationValues[submissionID].push(value);
      }
    });

    return submissionPublicationValues;
  }

  public getPublicationColumnsClass(submission: Submission, field: { control: string, id?: string }, value: boolean = undefined): string {
    if (this.colorScheme === ColorSchemeFilter['Publication status']) {
      if (value != undefined) {
        const className = value ? 'publication-ok' : 'publication-missing';
        return `${this.addBorderColumnClass(field.control)} ${className}`;
      }
  
      const className = this.customControlForm.get(`${field.id}-${submission.id}`).value ? 'publication-ok' : 'publication-missing';
      return `${this.addBorderColumnClass(field.control)} ${className}`;
    }

    return this.addBorderColumnClass(field.control);
  }

  public subtitleValues(status: SubmissionStatus): number {
    return this.dataSource.data.filter(({submission}) => submission.status === status).length;
  }
  
  public conflictSubtitleValues(conflict: 'span-under-A' | 'span-above-B' | 'span-between-A-and-B'): number {
    return this.dataSource.data.filter(item => {
      switch (conflict) {
        case 'span-under-A':
          return item.rank.span < this.eventReviewConfiguration.spanLimitA;
        case 'span-above-B':
          return item.rank.span > this.eventReviewConfiguration.spanLimitB;
        case 'span-between-A-and-B':
          return item.rank.span >= this.eventReviewConfiguration.spanLimitA && item.rank.span <= this.eventReviewConfiguration.spanLimitB;
      }
    }).length;
  }

  public getControlStatus(status: boolean) : string {
    return `admin.event.publication.status.${status ? 'checked' : 'unchecked'}`;
  }

  private orderFields(fields: string[]): string[] {	
    const intersectionResult = columnsOrder.filter(x => fields.indexOf(x) !== -1);	

    return intersectionResult;	
  }

  public addBorderColumnClass(column: string): string {
    const FIRST_COLUMN = this.fieldsShowOrdered[1];
    const LAST_COLUMN = this.fieldsShowOrdered[this.fieldsShowOrdered.length - 2];

    if (FIRST_COLUMN === column) return 'first-column';
    if (LAST_COLUMN === column) return 'last-column';

    return null;
  }

  public sortChange(sortData: {}): void {
    if (!sortData) return;

    const form = {sortBy: sortData['active'], reverseSort: sortData['direction'] === 'desc'};
    this.eventsService.putSubmissionFieldsAndFilters(this.event.id, form).subscribe();
  }

  public submitStatePublicationField(fieldID: number, submissionID: number): void {
    const data = {
      'checklist': fieldID,
      'submission': submissionID
    }

    this.publicationService.toggleSubmissionAnswer(this.event.id, data).subscribe();
  }

  public tableIsSorted(): boolean {
    return !!this.dataSource?.sort?.active;
  }

  public getTrackFiles(trackID: number): any {
    return this.event.tracks.find(track => track.id === trackID).trackFiles;
  }

  public getFile(files, trackFileID: number): File {
    return files.find(file => file.trackFile.id === trackFileID);
  }

  public rankingSpanClass(span: number): string {
    if (span < this.eventReviewConfiguration.spanLimitA) {
      return 'span-under-A';
    } else if (span > this.eventReviewConfiguration.spanLimitB) {
      return 'span-above-B';
    }
    return 'span-between-A-and-B';
  }

  public forceHover(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    this.previousElementHovered = target.closest('tr');

    this.previousElementHovered.classList.add('hovered');
  }

  public disableHover(): void {
    this.previousElementHovered.classList.remove('hovered');
  }

  public exportTable() {
    this.adminService.progressBar.start();

    setTimeout(() => {
      this.exportService.extractExportHTML('submission-table').subscribe(({ content }) => {

        const html = `
          <!doctype html>
          <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">

              <title>${this.event.name}</title>
              ${STYLES_EXPORT_TABLE_V2}
            </head>
            <body>
              <div class="export-container">
                <div class="submission-ranking">
                  ${this.replaceTags(content)}
                </div>
              </div>
            </body>
          </html>
        `;

        setTimeout(() => {
          const blob = new Blob([html], {type: 'text/html'});
          const downloadURL = URL.createObjectURL(blob);
          this.downloadLink(downloadURL, `${this.event.name}.html`);
          this.adminService.progressBar.stop();
        }, 1000);
      });

    }, 1000);
  }

  private replaceTags(content: string): string {3
    const aTag = /<a\b[^>]*>(.*?)<\/a>/g;
    const spanTag = '<span>$1</span>';
    const eyeSlashIcon = /<mat-icon\b[^>]*fonticon="fa-eye-slash"[^>]*>(.*?)<\/mat-icon>/g;
    const borderNoneIcon = /<mat-icon\b[^>]*fonticon="fa-border-none"[^>]*>(.*?)<\/mat-icon>/g;
    const appFileDownload = /<app-file-download\b[^>]*>(.*?)<\/app-file-download>/g;
    const matCheckboxTrue = /<mat-checkbox\b[^>]*checked[^>]*>(.*?)<\/mat-checkbox>/g;

    const aTagReplaced = this.replaceTagBySVG(content, aTag, spanTag);
    const eyeSlashReplaced = this.replaceTagBySVG(aTagReplaced, eyeSlashIcon, SVG.EYE_SLASH);
    const borderNoneReplaced = this.replaceTagBySVG(eyeSlashReplaced, borderNoneIcon, SVG.BORDER_NONE);
    const pdfReplaced = this.replaceTagBySVG(borderNoneReplaced, appFileDownload, SVG.PDF);
    const checkboxTrueReplaced = this.replaceTagBySVG(pdfReplaced, matCheckboxTrue, SVG.CHECK);

    return checkboxTrueReplaced;
  }

  private replaceTagBySVG(content: string, tag: RegExp, svg: string): string {
    return content.replace(tag, svg);
  }

  private downloadLink(downloadURL: string, name: string) {
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }

  private rank(submission: Submission): Rank {
    const completedReviews = submission.reviews.filter((r: Review) => r.status === ReviewStatus.COMPLETED);

    const reviews = {};
    const resultsByReview = {};

    completedReviews.forEach((review: Review) => {
      reviews[review.id] = {};

      review.form = _.cloneDeep(this.reviewForm);

      review.form.assignableFields.forEach(field => {          
        const sortedChoices = field.choices.sort((c1, c2) => c2.value - c1.value);
        const answers = review.answers.filter(a => a.formField === field.id);          
        field.answers = answers;

        reviews[review.id][field.id] = {
          globalWeight: field.globalWeight,
          weight: field.weight,
          value: field.choices.find(c => c.id === (
            field.answers?.length > 0 ?  // TODO: Remove these commented lines. Workaround for field with no answers.
              field.answers[0].fieldChoice :
              sortedChoices[sortedChoices.length - 1].id // Field should always have answers, since the review is completed.
          )).value,
          maxValue: sortedChoices[0].value > 0 ? sortedChoices[0].value : 1,
          field,
          reviewer: { id: review.userId, name: review.userName }
        };
      });

      const fields: Array<FormField> = [];
      let sum_average = 0, sum_weight = 0, sum_global_weight = 0;

      let reviewerInfo;
      for (const fieldId in reviews[review.id]) {
        if (Object.prototype.hasOwnProperty.call(reviews[review.id], fieldId)) {
          const { globalWeight, weight, value, maxValue, field, reviewer }: {
            globalWeight: number,
            weight: number,
            value: number,
            maxValue: number,
            field: FormField,
            reviewer: { id: number, name: string }
          } = reviews[review.id][fieldId];

          sum_average += (value / maxValue * weight);
          sum_weight += (value / maxValue * globalWeight);
          sum_global_weight += globalWeight;
          fields.push(field);

          if (!reviewerInfo) {
            reviewerInfo = reviewer;
          }
        }
      }

      resultsByReview[review.id] = {
        average: sum_average * 10,
        weight: sum_weight,
        globalWeight: sum_global_weight,
        fields,
        reviewer: reviewerInfo
      };
    });

    const result = plainToClass(Rank, {
      average: 0,
      weightedAverage: 0,
      span: 0,
      globalWeight: 0,
      fields: [],
      byReview: {
        reviews: [],
        labels: [],
        average: [],
        weight: [],
        answers: []
      }
    });

    // Uncompleted Reviews
    const uncompletedReviews = submission.reviews.filter((r: Review) => r.status != ReviewStatus.COMPLETED);
    uncompletedReviews.forEach((review: Review) => {
      result.byReview.reviews.push({ id: review.id.toString(), reviewer: { id: review.userId, name: review.userName }, answers: [] });
    });

    let weightedDivider = 0;
    let lowestAverage, biggestAverage;
    let lowestWeight, biggestWeight;
    for (const review in resultsByReview) {
      if (Object.prototype.hasOwnProperty.call(resultsByReview, review)) {
        const { average, weight, globalWeight, fields, reviewer } = resultsByReview[review];

        result.average += average;
        result.weightedAverage += (average * weight);
        weightedDivider += weight;

        result.globalWeight += globalWeight;

        if (average > biggestAverage || biggestAverage === undefined) {
          biggestAverage = average;
        }
        if (average < lowestAverage || lowestAverage === undefined) {
          lowestAverage = average;
        }

        if (weight > biggestWeight || biggestWeight === undefined) {
          biggestWeight = weight;
        }
        if (weight < lowestWeight || lowestWeight === undefined) {
          lowestWeight = weight;
        }

        if (result.byReview.labels.length < 1) {
          result.byReview.labels = (<FormField[]>fields).map(f => f.shortLabel);
        }

        // Setup field choice answer accumulator
        if (result.fields.length === 0) {
          result.fields = (<FormField[]>fields).map(f => {
            const fieldChoices: {
              [field: number]: {
                description: string,
                answers: number
              }
            } = {};

            f.choices.forEach(choice => {
              fieldChoices[choice.id] = { description: choice.description, answers: 0 };
            });

            return fieldChoices;
          });
        }

        const answersValue = (<FormField[]>fields).map((field, fieldIndex) => {
          const answer = (<Array<FormFieldChoice>>field.answerForReview)[0];

          // Accumulate amount of answer of this field choice
          if (answer) {
            result.fields[fieldIndex][answer.id].answers += 1;
          } else {
            return null;
          }


          return answer.value;
        });

        result.byReview.reviews.push({ id: review, reviewer: reviewer, answers: answersValue, average: average, weight: weight });
        result.byReview.average.push(average);
        result.byReview.weight.push(weight);
        result.byReview.answers.push(answersValue);
      }
    }
    
    if (!arrayHasElements(result.byReview.labels)) {
      result.byReview.labels = this.reviewForm?.assignableFields?.map(f => f.shortLabel) || [];
    }
    result.average /= Object.keys(resultsByReview).length;

    if (result.globalWeight === 0) {
      result.weightedAverage = result.average;
    } else {
      result.weightedAverage /= weightedDivider;
    }

    result.span = biggestAverage - lowestAverage;

    result.range = {
      average: {
        high: biggestAverage,
        low: lowestAverage
      },
      weight: {
        high: biggestWeight,
        low: lowestWeight
      }
    };

    return result;
  }
}
