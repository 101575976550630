import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { DashboardGraph } from 'src/app/model/dashboard';
import { AdminService } from 'src/app/service/admin.service';
import { ChartItem } from '../../model/chartItem';
import { SystemService } from '../../service/system.service';
import { DashboardDataset, DatasetOption } from '../../model/dashboard';
import { isEmpty, map } from 'rxjs/operators';
import { getLocale } from 'src/app/app.module';
import { P } from '@angular/cdk/keycodes';
import { keyframes } from '@angular/animations';
import html2canvas from 'html2canvas';
import { log } from 'console';


// Define the desired order for specific graphs, including all possible labels
const orderSubmissionsByStatus: string[] = ["active", "pending", "accepted", "rejected", "withdrawn"];
const orderReviewsByStatus: string[] = ["invited", "confirmed", "declined", "reminded", "late", "completed", "draft"];

@Component({
  selector: 'app-chart-component',
  templateUrl: './chart-component.component.html',
  styleUrls: ['./chart-component.component.scss']
})
export class ChartComponent implements OnInit {
  chartItem: ChartItem;
  @Input() graphPosition: DashboardGraph;
  chartClass: string;

  constructor(
    public adminService: AdminService,
    public translateService: TranslateService,
    public systemService: SystemService
  ) { }

  ngOnInit(): void {
    this.handleAsyncSettingsTranslations().subscribe(() => {
      this.chartItem = new ChartItem(this.graphPosition);
      this.refreshData();
      this.updateCaptionClasses();
      this.chartClass = this.graphPosition.title.split('.')[2];
      this.setLegendTitles();
    });
  }

  refreshData(): void {
    this.adminService.getDatasetData(this.chartItem.dataset.datasetName).subscribe(data => {
      // Correct 'undefined' and 'others' label for EVERY graph
      data[this.chartItem.dataset.datasetName].labels = (<string[]>data[this.chartItem.dataset.datasetName].labels).map(l => {
        if (l == 'undefined') {
          return this.translateService.instant('dataset-options.undefinedLabel')
        }else if(l == 'Others'){
          return this.translateService.instant('dataset-options.othersLabel')
        }else if(l===''){
          return this.translateService.instant('dataset-options.unknownCountryLabel')
        }else return l;
      });
      let indexMap: Record<string, number>;
      let newOrder: string[];
      switch (this.chartItem.dataset.datasetName) {
        case 'submissionsByCountry':
        case 'authorsByCountry':
          // Transform labels into country name using ISO codes
          this.systemService.countries.subscribe(countries => {
            data[this.chartItem.dataset.datasetName].labels = (<string[]>data[this.chartItem.dataset.datasetName].labels).map(l => {
              // Try getting the country name by iso code
              try {
                return countries.find(c => c.isoCode === l).name
              }
              catch(_) {
                return l
              }
          })
          });
        break;
        case 'submissionsByAffiliation':
        case 'authorsByAffiliation':
          // Correct specific label ('no_affiliation')
          data[this.chartItem.dataset.datasetName].labels = (<string[]>data[this.chartItem.dataset.datasetName].labels).map(l => {
            if(l =='no_affiliation'){
                return this.translateService.instant('dataset-options.no_affiliationLabel')
            }else return l;
          })
        break;
        case 'submissionsByStatus':
          // In case of 'submissionsByStatus' change the order of the labels to a specific one (and add classes to the slices to coloring purposes)


          // Create a map to hold the index of each label in the original array
          indexMap = (<string[]>data[this.chartItem.dataset.datasetName].labels).reduce((map, label, index) => {
            map[label] = index;
            return map;
          }, {} as Record<string, number>);

          // Filter the desired order to include only those labels that are in the original array
          newOrder = orderSubmissionsByStatus.map(label => label.toUpperCase())
          newOrder = newOrder.filter(label => label in indexMap);
          
          // Reorder the labels and series arrays based on the filtered new order
          data[this.chartItem.dataset.datasetName].labels = newOrder;
          data[this.chartItem.dataset.datasetName].series = <number[]>newOrder.map(label => data[this.chartItem.dataset.datasetName].series[indexMap[label]]);
          
          // Add a class to the slices of the pie so that the colors can be changed by CSS
          data[this.chartItem.dataset.datasetName].series = <object[]>(<number[]>data[this.chartItem.dataset.datasetName].series).map((value, index) => ({
            value: value,
            className: (<string>data[this.chartItem.dataset.datasetName].labels[index]).toLowerCase(),
          }));
        break;
        case 'reviewsByStatus':
          // In case of 'reviewsByStatus' change the order of the labels to a specific one (and add classes to the slices to coloring purposes)

          // Create a map to hold the index of each label in the original array
          indexMap = (<string[]>data[this.chartItem.dataset.datasetName].labels).reduce((map, label, index) => {
            map[label] = index;
            return map;
          }, {} as Record<string, number>);

          // Filter the desired order to include only those labels that are in the original array
          newOrder = orderReviewsByStatus.map(label => label.toUpperCase())
          newOrder = newOrder.filter(label => label in indexMap);
          
          // Reorder the labels and series arrays based on the filtered new order
          data[this.chartItem.dataset.datasetName].labels = newOrder;
          data[this.chartItem.dataset.datasetName].series = <number[]>newOrder.map(label => data[this.chartItem.dataset.datasetName].series[indexMap[label]]);
          
          // Add a class to the slices of the pie so that the colors can be changed by CSS (before translation!)
          data[this.chartItem.dataset.datasetName].series = <object[]>(<number[]>data[this.chartItem.dataset.datasetName].series).map((value, index) => ({
            value: value,
            className: (<string>data[this.chartItem.dataset.datasetName].labels[index]).toLowerCase(),
          }));
          break;
      }
      this.chartItem.data = data[this.chartItem.dataset.datasetName];


      // Translate labels
      // If the chart has the 'translateLabel' property set, all of the labels will be got from the language json
      if (this.chartItem.customOption) {
        if (this.chartItem.customOption.translateLabel) {
          forkJoin(
            (<string[]> data[this.chartItem.dataset.datasetName].labels)
              .map(l => `${this.chartItem.customOption.translateLabelPrepend}${l}`)
              .map(l => this.translateService.get(l))
          ).subscribe(labels => {
            data[this.chartItem.dataset.datasetName].labels = labels;
          });
        }
      }
    });
  }

  updateCaptionClasses(): void {
    // This function update the classes of the captions of pie charts, so that specific graphs can have custom colors in both caption and slices
    const startTime = Date.now(); // Record the start time

    const catchHTML1 = setInterval(() => {
      const captions_submissions = document.querySelectorAll('.submissionsByStatus .ct-legend > li')
      if (captions_submissions.length > 0) {        
        captions_submissions.forEach((caption) => {
          const text = caption.textContent?.trim();

          // Add class as '{label}-legend', such as 'pending-legend'
          // Uses always the english version of the label to create the class (uses the key of the language json)
          let statuses: Record<string, string> = this.translateService.instant('status')
          const class_prefix = (<string>Object.keys(statuses).find(key => statuses[key]===text)).toLowerCase()
          caption.classList.add(class_prefix + '-legend');
        });
        // Only disable interval after 100ms have passed
        // This ensures that the colors will be changed when the user change the graphs tab (like 'Submissions' to 'Reviews')
        if(Date.now() - startTime >= 100){
          clearInterval(catchHTML1);
        }
      }
    }, 1);
    const catchHTML2 = setInterval(() => {
      const captions_reviews = document.querySelectorAll('.reviewsByStatus .ct-legend > li')
      if (captions_reviews.length > 0) {        
        captions_reviews.forEach((caption) => {
          const text = caption.textContent?.trim();

          // Add class as '{label}-legend', such as 'pending-legend'
          // Uses always the english version of the label to create the class (uses the key of the language json)
          let statuses: Record<string, string> = this.translateService.instant('status')
          const class_prefix = (<string>Object.keys(statuses).find(key => statuses[key]===text)).toLowerCase()
          caption.classList.add(class_prefix + '-legend');
        });
        // Only disable interval after 100ms have passed
        // This ensures that the colors will be changed when the user change the graphs tab (like 'Submissions' to 'Reviews')
        if(Date.now() - startTime >= 100){
          clearInterval(catchHTML2);
        }
      }
    }, 1);
  }

  setLegendTitles(): void{
    // If it is a pie chart, set its legend title
    if(this.chartItem.type=='Pie'){
      this.chartItem.customOption.legendTitle = 'dataset-options.legends.'+this.chartItem.dataset.datasetName;
    }
  }

  handleAsyncSettingsTranslations(): Observable<any> {
    const dataset = (<DashboardDataset>this.graphPosition.dataset);
    const tasklist = [];

    dataset.options.forEach(opt => {
      const pendingTranslations: Array<string> = [];

      if (opt.name === 'ctAxisTitle') {
        const hasAxisDataset = dataset.type === 'Bar' || dataset.type === 'Line';
        if (hasAxisDataset) {
          const testParameters = [
            'axisX.axisTitle',
            'axisY.axisTitle'
          ];
    
          testParameters.forEach(parameter => {
            try {
              if (index(opt.settings, parameter)?.length > 0) {
                pendingTranslations.push(parameter)
              }
            } catch (error) {}
          });
        }
      }

      if (pendingTranslations.length > 0) {
        const task = this.translateService.get(pendingTranslations.map(l => index(opt.settings, l))).pipe(map(translations => {
          for (const key in translations) {
            if (Object.prototype.hasOwnProperty.call(translations, key)) {
              const parameter = pendingTranslations.find(l => index(opt.settings, l) === key)
              index(opt.settings, parameter, translations[key]);
            }
          }
        }));

        tasklist.push(task);
      }
    });

    if (tasklist.length > 0) {
      return forkJoin(tasklist);
    }
    return of(() => {});
  }
  saveAsImage(){
    const chartContainer = document.querySelector('.chart-container:has(.'+this.chartItem.dataset.datasetName+')') as HTMLElement;
    if (chartContainer) {
      // Hide the button before taking the screenshot
      const saveButton = chartContainer.querySelector('.save-chart-button') as HTMLElement;
        saveButton.style.display = 'none';

      html2canvas(chartContainer, {logging: false}).then((canvas) => {
        // Restore the button visibility after capturing the image
        saveButton.style.display = '';

        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
      }).catch(error => {
        console.error('Error capturing the chart:', error);
        // Ensure the button is restored in case of an erroR
          saveButton.style.display = '';
      });
    }
  }
}

// Used to access parameter and subparameters of objects, while passing a dot separated list of parameters of the object.
// EX: index(obj, 'a.b.c', 123) is equivalent to obj.a.b.c = 123
function index(obj: any, is: string | string[], value?: any) {
  if (typeof is === 'string')
    return index(obj, is.split('.'), value);
  else if (is.length == 1 && value !== undefined)
    return obj[is[0]] = value;
  else if (is.length == 0)
    return obj;
  else
    return index(obj[is[0]], is.slice(1), value);
}

