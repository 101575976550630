export const STYLES_EXPORT_TABLE_V2 = 
`
    <style>
        * {
            font-family: 'open sans', sans-serif;
        }

        .paperBackground {
            border-radius: 5px;
            color: #363636;
        }

        .accepted {
            background-color: #99FF99;
        }

        .active {
            background-color: #FFFF55;
        }

        .rejected {
            background-color: #FFBDBD;
        }

        .pending {
            background-color: #FAB65C;
        }

        .withdrawn {
            background-color: #DDDDDD;
        }

        th {
            text-align: center;
			vertical-align: middle;
			padding: 0.5rem 1.5rem;
			font-size: 0.875rem;
			font-weight: 600;
			border: none;
            color: #0000008A;
        }

        th:last-child {
            display: none;
        }

        td {
            color: #363636;
			text-align: center;
			vertical-align: middle;
			padding: 0.5em;
			font-size: 0.813rem;
        }

        td:first-child {
            background-color: white;
        }

        td:nth-child(2) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        td:nth-last-child(2) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .description {
            text-align: left;
        }

        .description .title {
            font-size: 1rem;
            font-weight: bold;
            text-align: left;
        }

        .description a {
            text-decoration: none;
        }

        .description a span {
            color: #0000EE;
            font-weight: 600;
        }

        .submission-topics {
            margin-top: 10px;
            margin-left: 0px;
        }

        .submission-topics .mat-chip-list-wrapper {
            display: flex;
            gap: 10px;
        }

        .submission-topics mat-chip {
            display: inline-flex;
            align-items: center;
            font-size: .75rem;
            font-weight: 400;
            margin-right: 1px;
            margin-top: 0px;
            padding: 0 10px;
            min-height: 1.4rem;
            white-space: nowrap;
            box-shadow: 0px 0px 1px rgba(0,0,0,.5);

            background-color: #ededed;
            color: #363636;
            border-radius: 10px;
        }

        .abstract {
            padding-top: .75rem;
            font-style: italic;
            text-align: justify;
            min-width: 500px;
        }

        .abstract span {
            font-weight: 700;
        }

        .rank-table, .files-table {
            display: inline-block;
            border-collapse: separate;
            border-spacing: 0px 1px;
        }

        .rank-table th, files-table th {
			white-space: pre-wrap;
			font-size: 0.75rem;
			font-weight: 400;
			vertical-align: bottom;
			color: rgba(0, 0, 0, .5);
			line-height: 1.2;
        }

        .rank-table th.file-icon-column {
            width: 100px;
        }

        .rank-table th:last-child {
            display: table-cell;
        }

        .rank-table td, files-table td {
            min-width: auto !important;
            height: 2em;
            padding: 2px 4px;
            border-top: 2px solid;
			border-bottom: 2px solid;
			border-left: none;
			border-color: transparent !important;
			background-color: #fff;
        }

        .rank-table td:first-child, .files-table td:first-child {
            background-color: #f8f8f8;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-left: 2px solid transparent;
        }

        .rank-table td:last-child, .files-table td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-right: 2px solid transparent;
        }

        .rank-table td.uncompleted-review, .rank-table td:nth-child(n+19) {
            background-color: #f8f8f8;
        }

        .rank-table td.average {
            font-weight: 600;
        }

        .files-table th:last-child {
            display: table-cell;
        }

        .files-table td {
            background-color: #f8f8f8;
        }

        .vbar {
			width: 1px;
            min-width: 1px !important;
			background-color: transparent !important;
			padding: 0 !important;
		}

        .submission-form-item .field-title {
            font-weight: bold;
        }

        .submission-form-item .field-description {
            font-style: italic;
        }

        app-checkbox-input:has(input[aria-checked="false"]) {
            display: none;
        }

        .select {
            display: none;
        }

        .container-subtitle {
            display: flex;
            justify-content: flex-start;

            margin: 1em 0 0 3em;
        }

        .legend {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5em;
        }

        .legend div span {
            font-weight: bold;
            padding: 0.25em;
            margin: 0 0.25em;
            min-width: 90px;
            display: inline-block;
            text-align: center;
        }

        .score-span {
            border-radius: 4px;
            padding: 3px;
        }

        .span-under-A {
            background-color: #75D775;
        }

        .span-above-B {
            background-color: #ff0000;
            font-weight: 700;
            color: rgb(255, 238, 238);
        }

        .span-between-A-and-B {
            background-color: #f0f04a;
        }

        app-menu-submission-list {
            display: none;
        }

        div[hidden] {
            display: none;
        }
    </style>
`;

export const STYLES_EXPORT_SUBMISSIONS = `
    <style>
        * {
            font-family: 'open sans', sans-serif;
        }

        h1 {
            font-weight: 600;
            font-size: 1.25rem;
        }

        a {
            text-decoration: none;
            cursor: default;
            color: inherit;
        }

        button {
            display: none;
        }

        .show-all-submissions-container {
            overflow: hidden;
        }

        .section {
            padding: 1rem 0;
            padding-top: 0px !important;
        }

        .section-title {
            font-weight: 600;
            font-size: 1.05rem;
            padding-bottom: 0.5rem;
        }

        .section .section-content {
            padding-left: 1.4rem;
        }

        .paper-info {
            font-size: 0.875rem;
        }

        .paper-info .name {
            padding-left: 1.4rem;
            width: 100%;
            display: inline-flex;
            padding-bottom: 0.25rem;
        }

        .paper-info .abstract {
            text-align: justify;
            padding-right: 4px;
            line-height: 19px;
        }

        .mat-chip-list-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .mat-chip {
            display: inline-flex;
            align-items: center;
            font-size: .75rem;
            font-weight: 600;
            margin-right: 3px;
            margin-top: 0px;
            padding: 0 10px;
            min-height: 1.4rem;
            white-space: nowrap;
            box-shadow: 0px 0px 1px rgba(0,0,0,.5);

            background-color: #ededed;
            color: #363636;
            border-radius: 10px;
        }

        .mat-chip:hover {
            background-color: rgba(0,0,0,.2);
        }

        .mat-standard-chip {
            transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
            display: inline-flex;
            border-radius: 16px;
            align-items: center;
            cursor: default;
            height: 1px;
        }

        .publication-status {
            color: white;
            margin-left: .5rem;
            font-weight: 600;
            font-size: 0.75rem;
            padding: 5px 13px;
            border-radius: 20px;
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }

        .publication-status.not-ready {
            background-color: #f93826;
        }

        .publication-status.ready {
            background-color: #6da36f;
        }

        .publication-info {
            margin-top: 1rem;
        }

        .publication-info .cover-item {
            display: flex;
            align-items: center;
            height: 36px;

            color: #6da36f;
        }

        .publication-info .cover-item.covered {
            color: #f93826;
        }

        .cover-item button {
            display: none;
        }

        .checkbox-input input {
            height: 15px;
            width: 15px;
        }

        table {
            width: 100%;
            border-radius: 6px;
            color: #363636;
            border-collapse: collapse;
            border-spacing: 0;
        }

        table tr {
            border-radius: 5px;
            height: 3rem;
        }

        .table.is-narrow td, .table.is-narrow th {
            padding: 0.25em 0.5em;
        }

        .paper-review-list tr.review td {
            vertical-align: middle;
        }

        .paper-review-list table td {
            border: none;
        }

        .paper-review-list tr.review td:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        .paper-review-list table td:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-right: none;
        }

        td.view.is-small button {
            display: none;
        }

        .paperBackground.review.completed {
            background-color: #63FCC8;
        }

        .paperBackground.review.reminded {
            background-color: #FF8FA0;
        }

        .paperBackground.review.confirmed {
            background-color: #FFD860;
        }

        .paperBackground.review.notified {
            background-color: #94C0FB;
        }

        .paperBackground.review.assigned {
            background-color: #FFF;
        }

        .paperBackground.review.declined {
            background-color: #C6C6C6;
        }

        .paperBackground.review.late {
            background-color: #fa5b5b;
        }

        .paperBackground.review.delegated {
            background-color: #ff89c9;
        }

        .paperBackground.review.draft {
            background-color: #AAAAAA;
        }

        .assignableField {
            display: flex;
        }

        .assignableField, .descriptiveField {
            margin-left: 3px;
            margin-top: 5px;
            font-weight: 600;
        }

        .assignableField .answer {
            font-weight: 600;
            font-size: .75rem;
            border: 1px solid rgba(0,0,0,.3);
            border-radius: 20px;
            padding: 0px 8px;
            margin-left: 2px;
            width: fit-content;
        }

        .descriptiveField {
            display: block;
        }

        .descriptiveField .answer {
            font-weight: normal;
            border-radius: 4px;
            padding: 8px 12px;
            margin: 0px 8px 8px 2px;
            border: 1px solid rgba(0,0,0,.2);
            font-family: 'Inconsolata';
            line-height: 17px;
            white-space: pre-wrap;
        }

        .author-review-view .reviewer {
            width: 90%;
        }

        .author-review-view .view {
            width: 10%;
            text-align: center;
        }

        .mat-card-content div {
            background-color: #ededed;
        }

        app-rebuttal > mat-card > mat-card-content > div {
            padding: 15px;
            border-radius: 5px;
        }

        app-multiline-text-input {
            display: none;
        }

        .rebuttal-text-container {
            display: block !important;
            margin-top: 15px;
            min-height: 100px;
            background-color: white !important;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 8px 12px;
            font-family: 'Inconsolata';
            line-height: 17px;
            white-space: pre-wrap;
        }

        app-rebuttal table .answer {
            background-color: white;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 8px 12px;
            font-family: 'Inconsolata';
            line-height: 17px;
            white-space: pre-wrap;
        }

        app-discussion-messages .discussion-messages {
            background-color: #ededed;
            width: 100%;
        }

        app-discussion-messages table {
            width: 100%;
            background-color: #ededed;
        }

        app-discussion-messages .mat-card-content > div {
            padding: 1rem;
            border-radius: 5px;
        }

        app-discussion-messages .mat-card-content .message {
            margin-bottom: 1em;
        }

        app-discussion-messages table tr {
            height: 30px;
        }

        app-discussion-messages table th {
            background-color: #d1d1d1;
        }

        app-discussion-messages table td {
            padding: 0 1em;
            vertical-align: middle;
            border-bottom-color: rgba(0, 0, 0, 0.12);
            border: 1px solid white;
        }

        app-discussion-messages table .mat-column-id {
            width: 5%;
        }

        app-discussion-messages table td:first-child, app-discussion-messages table th:first-child {
            border-left-style: none;
        }

        app-discussion-messages table td:last-child, app-discussion-messages table th:last-child {
            border-right-style: none;
        }
    </style>
`;
