import { Component, OnInit, Input, ViewChild, forwardRef, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { Submission } from '../../model/paper';
import { ReviewsService } from '../../service/reviews.service';
import { Review } from '../../model/paper';
import { AssignReview } from '../../model/assign.review';
import { InfoService } from '../../service/info.service';
import { User } from '../../model/user';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserSearchComponent } from '../user-search/user-search.component';
import { TranslateService } from '@ngx-translate/core';
import { ReviewsConfigurationService } from '../../service/reviews.configuration.service';
import { EventReviewConfiguration } from '../../model/eventReviewConfiguration';
import { NotificationService } from 'src/app/service/notification.service';
import { AuthService } from 'src/app/service/auth.service';
import { UserEventLimit } from 'src/app/component/user-search/user-search.component';
import { forkJoin } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TimezoneService } from 'src/app/service/timezone.service';

@Component({
  selector: 'app-assign-reviewers',
  templateUrl: './assign-reviewers.component.html',
  styleUrls: ['./assign-reviewers.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AssignReviewersComponent),
    multi: true
  }]
})
export class AssignReviewersComponent implements OnInit, OnChanges {
  @Input() submission: Submission;
  @Input() assignExternal = false;
  @Input() eventTimeZone: string;

  public showCreateUserForm = false;

  reviews: Array<Review>;
  newReview: AssignReview;

  form: FormGroup;

  reviewConfiguration: EventReviewConfiguration;

  @ViewChild('search') search: UserSearchComponent;
  @ViewChild('searchExternal') searchExternal: UserSearchComponent;

  link;
  @Output() reviewsChanged = new EventEmitter<Review[]>();

  canAssignToExternalReviewers: boolean;
  @Input() assignedReview: Review;
  userEventLimit: UserEventLimit;

  constructor(
    public fb: FormBuilder,
    public reviewsService: ReviewsService,
    public infoService: InfoService,
    public reviewConfigurationService: ReviewsConfigurationService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private dialog: MatDialog,
    private tzService: TimezoneService
  ) { }

  addReview(): void {
    const user = this.form.value.user[0];

    if (this.userHasAssignmentConflict(user)) {
      this.notificationService.notifyError('reviews.assign-conflict-detected', {
        params: { user: `#${user.id} ${user.name}` }
      });
    } else {
      const review = new AssignReview();

      review.assignedBy = this.infoService.user.id;

      review.user = user;
      review.dueAt = this.form.value.dueAt;
      review.submission = this.submission.id;

      this.newReview = review;

      this.checkValidReview(() => {
        this.saveReview();
        this.clearSearchInput();
      });
    }
  }

  clearSearchInput(): void {
    this.search.formGroupController.get(this.search.name).setValue([]);
    if (this.search.input) {
      this.search.clearNativeInput();
    }
    this.form.get('dueAt').setValue(this.reviewConfiguration.conferenceDateDue.toDate());
  }

  checkValidReview(callback: () => void) {
    const dueAtPassed = this.newReview.dueAt < this.today();

    if (dueAtPassed) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'reviews.invalid-date-set',
          content: ''
        }
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          callback();
        }
      });
    } else {
      callback();
    }
  }

  saveReview(): void {
    this.newReview.user = this.newReview.user instanceof User ? this.newReview.user.id : this.newReview.user;
    this.reviewsService.createReview(this.newReview).subscribe(review => {
      const user = <User>review.user;
      this.notificationService.notify('messages.assigned-user-review', { params: { user: `#${user.id} ${user.name}` } });
      this.reviews.push(review);
      this.reviewsChanged.emit(this.reviews);
    });
  }

  ngOnInit() {
    this.link = {
      label: 'forms.buttons.assign-review-date',
      route: `/admin/${this.submission.event.id}/reviews/configuration`,
      align: 'end'
    };

    forkJoin([
      this.reviewConfigurationService.getReviewsConfiguration(this.submission.event.id),
      this.reviewsService.getReviewsBySubmissionId(this.submission.id),
      this.authService.userIsAdminOrChairOrCommittee(this.submission.event.id)
    ]).subscribe(([rConfiguration, reviews, isAuthorized]) => {
      this.reviewConfiguration = rConfiguration;
      this.form = this.fb.group({
        user: ['', [Validators.required]],
        dueAt: [this.reviewConfiguration.conferenceDateDue?.toDate(), [Validators.required]],
        timeZone: [this.tzService.browserTimeZone],
        externalUser: ['']
      });

      this.reviews = reviews;

      this.canAssignToExternalReviewers = this.reviewConfiguration.tpcAssignToExternal && isAuthorized;

      // if (this.canAssignToExternalReviewers) {
      //   const limit = new UserEventLimit();
      //   limit.role = UserEventRole.COMMITTEE;
      //   limit.event = this.submission.event.id;
      //   limit.exclude_role = true;

      //   // this.userEventLimit = limit;
      // }
    });
  }

  ngOnChanges(changed: { submission: SimpleChange }): void {
    if (changed.submission && !changed.submission.firstChange) {
      const submission = (<Submission>changed.submission.currentValue);
      const reviews = submission.reviews;

      if (reviews.length > 0) {
        if (reviews[0] instanceof Review) {
          this.reviews = <Review[]>reviews;
        } else {
          this.reviewsService.getReviewsBySubmissionId(submission.id).subscribe(r => {
            this.reviews = r;
          });
        }
      } else {
        this.reviews = [];
      }
    }
  }

  today(): Date {
    return new Date();
  }

  assignToSelectedUser() {
    const user = <User> this.form.value.externalUser[0];

    if (this.userHasAssignmentConflict(user)) {
      this.notificationService.notifyError('reviews.assign-conflict-detected', {
        params: { user: `#${user.id} ${user.name}` }
      });
    } else {
      this.assignExternalReview(user);
    }
  }

  assignExternalReview(user: User) {
    this.reviewsService.assignReview(this.assignedReview.id, user.id).subscribe(review => {
      this.notificationService.notify('messages.assigned-external-user-review', { params: { user: user.firstName + ' ' + user.lastName } });
      this.assignedReview = review;

      this.reviews = [this.assignedReview];
      this.reviewsChanged.emit(this.reviews);
    });
  }

  userHasAssignmentConflict(user: User): boolean {
    return this.submission.isAuthor(user.id) || this.reviewsService.userIsReviewer(user, this.reviews);
  }
}
